import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Card } from 'react-bootstrap';
import { viewResetAction } from '../../redux/actions/dashboardActions';
import { connect } from "react-redux";
import { viewColDefs } from '../../helpers/viewDatasetColDef';
import backIcon from '../../assets/images/back.png';

class ViewDataSet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowSelectionData: [],
      columnDefs: viewColDefs,
      rowSelection: "multiple",
      suppressRowClickSelection: true,
      rowData: [],
    };

  }

  componentDidMount() {
    let data = []
    data.push(this.props.viewData.data)

    this.setState({
      rowData: data
    })
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.setState({ rowData: this.state.rowData });
  }

  backToDashboard = () => {
    this.props.viewResetAction()
  }
  render() {
    const { columnDefs, rowSelection, rowData, suppressRowClickSelection } = this.state;
    return (
      <div>
        <Card className="content-wrapper border-0">
          <Card.Title className="fs-18 fw-semibold d-md-flex" >
            <img className="curpoint" src={backIcon} height={18} onClick={this.backToDashboard} alt="viewicon" />View Datasets
          </Card.Title>


          <Card.Body>
            <div className="ag-theme-alpine" style={{ height: 500 }}>
              <AgGridReact
                columnDefs={columnDefs}
                rowSelection={rowSelection}
                onGridReady={this.onGridReady.bind(this)}
                rowData={rowData}
                suppressRowClickSelection={suppressRowClickSelection}
              />
            </div>
          </Card.Body>

        </Card>
      </div>
    )
  }
}

const mapStatetoProps = (state) => {
  return {
    viewData: state.reducerDashboard.viewData
  }
}



export default connect(
  mapStatetoProps, {
  viewResetAction
})(ViewDataSet);