import { call, put, takeLatest } from 'redux-saga/effects';
import {
  IMPORT_DATASET_LOADING, IMPORT_DATASET_SUCCESS,
  IMPORT_DATASET_FAIL, IMPORT_NEW_DATASET,
  ALL_DATASET_LOADING, ALL_DATASET_SUCCESS,
  ALL_DATASET_FAIL, ALL_DATASET, DATABASE_IMPORT_LOADING,
  DATABASE_IMPORT_SUCCESS, DATABASE_IMPORT_FAIL,
  IMPORT_TO_DATABASE, CLEAR_DATABASE_LOADING, CLEAR_DATABASE_SUCCESS,
  CLEAR_DATABASE_FAIL, CLEAR_DATABASE, VIEW_DATASET_LOADING,
  VIEW_DATASET_SUCCESS, VIEW_DATASET_FAIL, VIEW_DATASET, DELETE_DATASET_LOADING,
  DELETE_DATASET_SUCCESS, DELETE_DATASET_FAIL, DELETE_DATASET
} from '../helpers/constants';

import {
  importNewDataset_service, showAllDataset_service,
  importDatasetDb_service, clearDatabase_service,
  viewDataset_service, deleteDataset_service
} from '../services/dashBoardServices/index';

function* importDataset(action) {
  let dataset = action.payload.data;

  try {
    yield put({ type: IMPORT_DATASET_LOADING });
    const importData = yield call(importNewDataset_service, dataset);

    if (importData) {
      let status = importData.data.status

      if (status === 200) {
        yield put({ type: IMPORT_DATASET_SUCCESS, payload: { importData } });
        yield put({ type: ALL_DATASET })
      }

      else if (status !== 200 || !importData) {
        yield put({ type: IMPORT_DATASET_FAIL, payload: { importData } });
        yield put({ type: ALL_DATASET })
      }
    }

  } catch (e) {
    yield put({ type: IMPORT_DATASET_FAIL, payload: { e } });
  }
}

function* getAllDatasets(action) {

  try {
    yield put({ type: ALL_DATASET_LOADING });
    const allDataset = yield call(showAllDataset_service);
    let result = allDataset.data
    yield put({ type: ALL_DATASET_SUCCESS, payload: { result } });


  } catch (e) {
    yield put({ type: ALL_DATASET_FAIL, payload: { e } });
  }
}


function* importDataToDatabase(action) {
  let data = action.payload.data;

  try {
    yield put({ type: DATABASE_IMPORT_LOADING });
    const impData = yield call(importDatasetDb_service, data);

    let newdata = impData.data
    let status = newdata.status
    if (status === 200) {
      yield put({ type: DATABASE_IMPORT_SUCCESS, payload: { newdata } });
      yield put({ type: ALL_DATASET })
    }

    else if (status !== 200) {
      yield put({ type: DATABASE_IMPORT_FAIL, payload: { newdata } });
      yield put({ type: ALL_DATASET })
    }

  } catch (e) {
    yield put({ type: DATABASE_IMPORT_FAIL, payload: { e } });
  }
}

function* clearDatabase(action) {

  try {
    yield put({ type: CLEAR_DATABASE_LOADING });
    const clrData = yield call(clearDatabase_service);
    let clearsp = clrData.data
    let status = clearsp.status
    if (status === 200) {
      yield put({ type: CLEAR_DATABASE_SUCCESS, payload: { clearsp } });
      yield put({ type: ALL_DATASET })
    }

    else {
      yield put({ type: CLEAR_DATABASE_FAIL, payload: { clearsp } });
    }

  } catch (e) {
    yield put({ type: CLEAR_DATABASE_FAIL, payload: { e } });
  }
}


function* viewDataset(action) {

  let data = action.payload.data;

  try {
    yield put({ type: VIEW_DATASET_LOADING });
    const viewData = yield call(viewDataset_service, data);
    yield put({ type: VIEW_DATASET_SUCCESS, payload: { viewData } });

  } catch (e) {
    yield put({ type: VIEW_DATASET_FAIL, payload: { e } });
  }
}

function* deleteDataset(action) {
  let data = action.payload.data;

  try {
    yield put({ type: DELETE_DATASET_LOADING });
    const deleteData = yield call(deleteDataset_service, data);
    yield put({ type: DELETE_DATASET_SUCCESS, payload: { deleteData } });
    yield put({ type: ALL_DATASET })

  } catch (e) {
    yield put({ type: DELETE_DATASET_FAIL, payload: { e } });
  }
}


function* dashboardSaga() {
  yield takeLatest(IMPORT_NEW_DATASET, importDataset)
  yield takeLatest(ALL_DATASET, getAllDatasets)
  yield takeLatest(IMPORT_TO_DATABASE, importDataToDatabase)
  yield takeLatest(CLEAR_DATABASE, clearDatabase)
  yield takeLatest(VIEW_DATASET, viewDataset)
  yield takeLatest(DELETE_DATASET, deleteDataset)
}

export default dashboardSaga;