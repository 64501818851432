import axios from "axios";
import { LOGIN_API } from "../../apis";
import { LOGIN_HEADER } from "../../helpers/apiHeaders";

export const loginUser = async (user, password) => {
  return await axios({
    method: 'post',
    url: `${LOGIN_API}`,
    headers: LOGIN_HEADER,
    data: {
      username: user,
      password: password
    }
  })

};




