import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { ERROR_SVG } from '../../assets/svgFiles/svgIcons';
import { IMPORT_DB_MSG } from '../../helpers/alertMessages';

export const FailAlert = (props) => {
  const { isShow, close, message } = props;


  return (
    <Modal className="alert-message-modal" show={isShow} onHide={close.bind(this, false)} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header className="border-0 p-3" closeButton>
        {/* <Modal.Title>Modal heading</Modal.Title> */}
      </Modal.Header>
      <Modal.Body className="text-center">
        <div className="statuscircle danger">
          {ERROR_SVG}
        </div>
        <h4 className="text-black fs-14 fw-bold">Error Detected</h4>
        <p className="text-black fs-14 mb-0">{message }</p>
      </Modal.Body>
    </Modal>
  );
}