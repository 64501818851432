import React from 'react';
import ViewIcon from '../assets/svgFiles/view.svg';
import {  useDispatch } from 'react-redux';
import allActions from '../redux/actions/allActions';


const AgGridViewData = (props) => {

  const dispatch = useDispatch();

  const viewIconHandler = () => {
    const data = props.data
    dispatch(allActions.dashboardActions.datasetViewAction(data.metadata))
  }

  return (
    <div className='viewdata'>
      <img className="curpoint" src={ViewIcon} onClick={viewIconHandler.bind(this)} alt="viewicon" />
    </div>
  );
}

export default AgGridViewData;