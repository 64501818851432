import { ALL_DATASET, CLEAR_DATABASE, DELETE_DATASET, IMPORT_NEW_DATASET,
     IMPORT_TO_DATABASE, VIEW_DATASET,IMPORT_RESET,DB_RESP_RESET,
     VIEW_RESP_RESET } from "../../helpers/constants";

const newDatasetImport = (data) => {
    return {
        type: IMPORT_NEW_DATASET,
        payload: {
            data: data
        }
    }
}

const getAllDataSets = () => {
    return {
        type: ALL_DATASET
    }
}


export const datasetImportDb = (data) => {
    return {
        type: IMPORT_TO_DATABASE,
        payload: {
            data: data
        }
    }
}

export const clearDatasetDb = (data) => {
    return {
        type: CLEAR_DATABASE,
        payload: {
            data: data
        }
    }
}


 const datasetViewAction = (data) => {
    return {
        type: VIEW_DATASET,
        payload: {
            data: data
        }
    }
}

const datasetDeleteAction = (data) => {
    return {
        type: DELETE_DATASET,
        payload: {
            data: data
        }
    }
}

const importResetAction = () => {
    return {
        type: IMPORT_RESET,
    }
}


export const dbRespResetAction = () => {
    return {
        type: DB_RESP_RESET,
    }
}

export const viewResetAction = () => {
    return {
        type: VIEW_RESP_RESET,
    }
}

export default {
    newDatasetImport,
    getAllDataSets,
    datasetViewAction,
    datasetDeleteAction,
    importResetAction,

}