export const LOGIN_HEADER = {
    'Content-Type': 'application/json',
    'access-control-allow-origin': '*',
  };


export const UPLOAD_API_HEADER = {
    'access-control-allow-origin': '*',
    "refresh_token":"eyJhbGciOiJIUzI1Ni",
    "token_type":"Bearer",
    "access_token":"eyJhbGciOiJIUzI1NiJ9",
    'Content-Type':"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"

  };


  export const GENRAL_HEADER = {
    'Content-Type': 'application/json',
    'access-control-allow-origin': '*',
    "refresh_token":"eyJhbGciOiJIUzI1Ni",
    "token_type":"Bearer",
    "access_token":"eyJhbGciOiJIUzI1NiJ9"

  };