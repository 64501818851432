import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { CLEAR_DB_MSG, DELETE_DATA_MSG } from '../../helpers/alertMessages';


export const ConfirmModel = ({ isShow, closeAlert, confirmSave, confirmFrom }) => {
  const [show, setShow] = useState(isShow);

  return (
    <div>

      <Modal show={show} onHide={closeAlert.bind(this, false)}>
        <Modal.Header closeButton>
          <Modal.Title className="fs-18 fw-semibold d-md-flex align-items-center justify-content-between">
            {confirmFrom === "Clear_DB" ? "Clear Database" :
              confirmFrom === "Delete_Icon" ? "Delete Dataset" : ""}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-black fs-14 mb-0">
          {confirmFrom === "Clear_DB" ? CLEAR_DB_MSG :
            confirmFrom === "Delete_Icon" ? DELETE_DATA_MSG : ""}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeAlert.bind(this, false)}>
            Close
          </Button>

          <Button variant="danger" onClick={confirmSave.bind()}>
            {confirmFrom === "Clear_DB" ? "Clear" : "Delete"}
          </Button>

        </Modal.Footer>
      </Modal>
    </div>
  )
}



