import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Card, Button } from 'react-bootstrap';
import { IMPORT_DB_SVG, CLEAR_DB_SVG } from '../../assets/svgFiles/svgIcons';
import { FailAlert } from '../alertModel/FailAlert';
import { CLEAR_DB_MSG, IMPORT_DB_MSG, DB_SUCCESS_MSG } from '../../helpers/alertMessages';
import { colDefs } from '../../helpers/agGridColDef';
import { connect } from "react-redux";
import dashboardActions, { datasetImportDb, clearDatasetDb, dbRespResetAction, viewResetAction } from '../../redux/actions/dashboardActions';
import ViewDataSet from './ViewDataSet';
import { SuccessAlert } from '../alertModel/SuccessAlert';
import { ImportFailAlert } from '../alertModel/ImportFailAlert';
import Modal from 'react-bootstrap/Modal';
import { ConfirmModel } from '../alertModel/ConfirmModel';



class AllDataSets extends Component {
  constructor(props) {
    super(props);
    this.loadingTemplate =
      `<h3 class="">Loading...</h3>`;
    this.noRowsTemplate =
      `${this.props.rowDatasets.length < 1 ? `<span>no rows to show</span>` : `<span>Loading...</span>`}`;
    this.state = {
      rowSelectionData: [],
      isError: false,
      alrtMsg: "",
      columnDefs: colDefs,
      rowSelection: "multiple",
      suppressRowClickSelection: true,
      rowData: this.props.rowDatasets,
      isSuccess: false,
      showConfirm: false,
      confirmFrom: null
    };

  }

  componentDidMount() {
    this.props.dbRespResetAction()
    this.props.viewResetAction()
    this.setState({
      rowData: this.props.rowDatasets.length > 0 ? this.props.rowDatasets : [],

    })
  }

  componentDidUpdate(prevProps) {

    if (this.props.rowDatasets !== this.props.rowDatasets) {
      this.props.dbRespResetAction()
      this.setState({
        rowData: this.props.rowDatasets.length > 0 ? this.props.rowDatasets : [],
      })
    }

    if (prevProps.impDbData !== this.props.impDbData) {

      if (this.props.impDbData.status !== 200 && this.props.impDbData.isSuccess === false) {

        this.setState({
          isError: true,
          alrtMsg: this.props.impMsgFail,
          isSuccess: false
        })
      }

      if (this.props.impDbData.status === 200 && this.props.impDbData.isSuccess === false) {

        this.setState({
          isError: true,
          alrtMsg: this.props.impDbData.data,
          isSuccess: false
        })
      }

      if (this.props.impDbData.status === 200 && this.props.impDbData.isSuccess === true) {

        this.setState({
          isError: false,
          isSuccess: true,
          alrtMsg: DB_SUCCESS_MSG,

        })
      }

    }

  }


  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (nextProps.rowDatasets !== nextState.rowData) {
      this.setState({
        rowData: nextProps.rowDatasets,

      })
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.setState({ rowData: this.state.rowData });
  }

  onSelectionChanged = () => {
    let finalRowData = []
    var selectedRows = this.gridApi.getSelectedRows();
    selectedRows.forEach(function (selectedRow, index) {
      finalRowData.push(selectedRow.metadata)
    });

    this.setState({
      rowSelectionData: finalRowData
    })

  }



  importDatabse = () => {
    if (this.state.rowSelectionData.length < 1) {

      this.setState({
        isError: true,
        alrtMsg: IMPORT_DB_MSG,
        isSuccess: false
      })
    }

    else {
      this.props.datasetImportDb(this.state.rowSelectionData)

      this.setState({
        rowSelectionData: [],
      })
    }
  }

  clearDatabase = () => {
    this.setState({
      showConfirm: true,
      confirmFrom: "Clear_DB"
    })

  }

  closeAlert = (val) => {
    this.props.dbRespResetAction()
    this.setState({
      isError: val,
      alrtMsg: "",
      isImptoDb: false,
      isSuccess: val,
      showConfirm: false
    })
  }

  confirmSave = () => {
    this.props.clearDatasetDb()

    this.setState({
      showConfirm: false
    })
  }


  render() {
    const {
      columnDefs, rowSelection, suppressRowClickSelection, isError,
      alrtMsg, isSuccess, rowData, showConfirm, confirmFrom
    } = this.state;

    const { viewData } = this.props;
    return (
      <div>
        {
          viewData.status === 200 ? <ViewDataSet /> :
            <Card className="content-wrapper border-0">
              <Card.Title className="fs-18 fw-semibold d-md-flex align-items-center justify-content-between">All Datasets
                <div className="title-btn">
                  <Button className="me-2 mt-2 mt-md-0 btn-primary-outline fs-13" onClick={this.importDatabse}>
                    {IMPORT_DB_SVG} Import to Database
                  </Button>
                  <Button className="btn-danger-outline mt-2 mt-md-0 fs-13" onClick={this.clearDatabase}>
                    {CLEAR_DB_SVG} Clear Database
                  </Button>
                </div>
              </Card.Title>
              <Card.Body>
                <div className="ag-theme-alpine" style={{ height: 500 }}>
                  <AgGridReact
                    columnDefs={columnDefs}
                    rowSelection={rowSelection}
                    onGridReady={this.onGridReady.bind(this)}
                    onSelectionChanged={this.onSelectionChanged.bind(this)}
                    rowData={rowData}
                    suppressRowClickSelection={suppressRowClickSelection}
                    overlayLoadingTemplate={this.loadingTemplate}
                    overlayNoRowsTemplate={this.noRowsTemplate}
                  />
                </div>
              </Card.Body>
              {
                isError && (
                  <ImportFailAlert
                    isShow={isError}
                    close={this.closeAlert}
                    message={alrtMsg}
                  />
                )
              }

              {
                isError && (alrtMsg === IMPORT_DB_MSG) ?
                  <FailAlert
                    isShow={isError}
                    close={this.closeAlert}
                    message={alrtMsg}
                  /> : ""

              }

              {
                isSuccess && (
                  <SuccessAlert
                    isShow={isSuccess}
                    close={this.closeAlert}
                    message={alrtMsg}
                  />
                )
              }

              {
                showConfirm &&
                <ConfirmModel
                  isShow={showConfirm}
                  closeAlert={this.closeAlert}
                  confirmSave={this.confirmSave}
                  confirmFrom={confirmFrom} />
              }
            </Card>
        }
      </div>
    );
  }
}


const mapStatetoProps = (state) => {
  return {
    rowDatasets: state.reducerDashboard.allDatasets,
    impDbData: state.reducerDashboard.dbImportData,
    impMsgFail: state.reducerDashboard.message,
    viewData: state.reducerDashboard.viewData
  }
}


export default connect(
  mapStatetoProps, {
  datasetImportDb,
  clearDatasetDb,
  dbRespResetAction,
  viewResetAction
})(AllDataSets)