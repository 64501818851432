import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { SUCCESS_SVG } from '../../assets/svgFiles/svgIcons';
import { DB_SUCCESS_MSG } from '../../helpers/alertMessages';

export const SuccessAlert = (props) => {
  const { isShow, close, message } = props;

  return (
    <Modal className="alert-message-modal" show={isShow} onHide={close.bind(this, false,message)} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header className="border-0 p-3" closeButton>
        {/* <Modal.Title>Modal heading</Modal.Title> */}
      </Modal.Header>

      <Modal.Body className="text-center">
        <div className="statuscircle success">
          {SUCCESS_SVG}
        </div>
        <p className="text-black fs-14 mb-0">{message}</p>
        {
          message !== DB_SUCCESS_MSG ?  <h4 className="text-black fs-14 fw-bold">Amazon S3 Bucket</h4> : ""
        }
       
      </Modal.Body>

    </Modal>
  );
}