import React from 'react'

export default function Footer() {
  const date = new Date();
  let year = date.getFullYear();
  return (
    <div className='footer-section bg-white fixed-bottom p-2'>
      <p className="fs-13 m-0 text-center primary-color">Copyright &#169; {year}. All Rights Reserved</p>
    </div>
  )
}
