import moment from 'moment';
import AgGridDelete from '../layouts/AgGridDelete';
import AgGridViewData from '../layouts/AgGridViewData';

//This file is used for ag-grid columns and data represent related changes.

export const colDefs = [
  {
    headerName: '',
    field: 'check',
    editable: false,
    //  cellRendererFramework: AgGridCheckbox,
    checkboxSelection: true,
    headerCheckboxSelection: true,
    suppressMovable: true,
    width: 80,
    headerClass: function (params) {
      return 'header-one';
    }
  },


  {
    headerName: 'SN',
    field: 'metadata',
    editable: false,
    width: 100,
    suppressMovable: true,
    valueGetter: 'node.rowIndex + 1'

  },

  {
    headerName: 'Name',
    field: 'dataset_name',
    editable: false,
    width: 250,
    suppressMovable: true,
  },

  {
    headerName: 'Size',
    field: 'size',
    editable: false,
    width: 120,
    suppressMovable: true,
    cellRenderer: (data) => {
      return `${Math.ceil(data.data.size / 1000)}kb`
    }
  },

  {
    headerName: 'Imported on',
    field: 'date',
    editable: false,
    width: 190,
    suppressMovable: true,
    cellRenderer: (data) => {
      return moment(data.data.date).format('DD-MM-YYYY, hh:mm A')
    }
  },

  {
    headerName: 'Action',
    field: 'action',
    editable: false,
    cellRenderer: AgGridViewData,
    width: 40,
    suppressMovable: true,
    headerClass: function (params) {
      return 'overflow-visible';
    },
    cellClass: function (params) {
      return 'viewicon';
    }

  },

  {
    headerName: '',
    field: 'action',
    editable: false,
    // cellRenderer:  AgGridDelete ,
    width: 10,
    cellRendererSelector: (params) => {
      if (params.data.isactive === false) {
        return {
          component: AgGridDelete,
          params: {}
        };
      } else {
        return
      }
    },
    suppressMovable: true,
    cellClass: function (params) {
      return params.data.isactive === false ? 'deleteicon' : "";
    }
  },
];