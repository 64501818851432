import React from 'react';

export const  MsgLayout=(props)=> {
    const { failMsg } = props;
    return (
        <div>
            {
                failMsg.map((data, id) => {
                    return (
                        <li key={id} className="text-black fs-14">{data} </li>
                    )
                })
            }
        </div>
    )
}
