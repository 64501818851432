import { configureStore, combineReducers } from '@reduxjs/toolkit';
import loginReducer from './reducers/loginReducer';
import createSagaMiddleware from '@redux-saga/core';
import loginSaga from '../saga/loginSaga';
import dashboardSaga from '../saga/dashboardSaga';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import dashboardReducer from './reducers/dashboardReducer';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: hardSet,
}

let rootReducer = combineReducers({
  reducerLogin: loginReducer,
  reducerDashboard: dashboardReducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const SagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      thunk: false,
      serializableCheck: false,
    }).prepend(SagaMiddleware);
  },
});

SagaMiddleware.run(loginSaga);
SagaMiddleware.run(dashboardSaga);

export const persistor = persistStore(store)