import React, { useEffect, useState } from 'react';
import { Card, Button } from 'react-bootstrap';
import { FileUploader } from "react-drag-drop-files";
import { FILE_TYPE_ERROR, SELECT_DATASET, DATASET_SUCCESS } from '../../helpers/alertMessages';
import { FILE_TYPE } from '../../helpers/constants';
import FileDropLayot from '../../layouts/FileDropLayot';
import { INFO_SVG_ICON, IMPORT_SVG_ICON } from '../../assets/svgFiles/svgIcons';
import { useSelector, useDispatch } from 'react-redux';
import allActions from '../../redux/actions/allActions';
import { SuccessAlert } from '../alertModel/SuccessAlert';
import { ImportFailAlert } from '../alertModel/ImportFailAlert';
import { FailAlert } from '../alertModel/FailAlert';
import CloseIcon from '../../assets/images/close.png'


export default function ImportDataSet() {
  const dispatch = useDispatch();
  const fileTypes = FILE_TYPE;
  const [file, setFile] = useState([]);
  const [show, setShow] = useState(false);
  const [showSuccess, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [fileName,setFilename] = useState("")


  // dispatch(allActions.dashboardActions.importResetAction())
  const dataStatus = useSelector(state => state.reducerDashboard.status);
  const impFailmsg = useSelector(state => state.reducerDashboard.message);
  const isLoading = useSelector(state => state.reducerDashboard.loading)

  useEffect(() => {
    if (dataStatus === 200) {
      setSuccess(true)
      setShow(false)
      setMessage(DATASET_SUCCESS)


    }

    else if (dataStatus !== 200 && dataStatus !== null) {
      setSuccess(false)
      setShow(true)
      setMessage(impFailmsg)

    }

  }, [dataStatus, impFailmsg])


  const handleChange = (data) => {
    setFilename(data.name)
    setFile(data);
    setShow(false)
    setMessage("")
    setSuccess(false)
  };

  const fileTypeHandler = (error) => {
    setShow(true)
    setMessage(FILE_TYPE_ERROR)
  }

  const fileDropHandler = (data) => {
    setShow(false)
    setMessage("")
    setSuccess(false)
  }

  const closeAlert = (val, msg) => {
    setShow(val)
    setSuccess(val)
    setMessage("")
    dispatch(allActions.dashboardActions.importResetAction())
    if (msg !== undefined) {
      window.location.reload()
    }

  }

  const importDatasets = async (e) => {
    e.preventDefault()
    if (file.length < 1) {
      setShow(true)
      setMessage(SELECT_DATASET)
    }
    else {

      let fileData = await readFileDataAsBase64(file);
      let file_result = fileData.split(',').pop();
      dispatch(allActions.dashboardActions.newDatasetImport(file_result))

    }
  }

 const removeFileHandler = ()=>{
  console.log("remove file handler call")
  // window.location.reload()
    setFilename("")
    setFile([]);
    setShow(false)
    setMessage("")
    setSuccess(false)

 }


  const readFileDataAsBase64 = async (data) => {
    const file = data;

    return await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = (event) => {
        resolve(event.target.result);

      };

      reader.onerror = (err) => {
        reject(err);
      };


    });
  }
  return (
    <div>
      {
        isLoading ?  <div className='loader mrleft'></div> : 
        <Card className="content-wrapper border-0">
        <Card.Title className="fs-18 fw-semibold">Import Dataset</Card.Title>
        <Card.Body>
          <div className="drag-and-drop-part text-center pt-4">
            <FileUploader
              multiple={false}
              handleChange={handleChange}
              name="file"
              types={fileTypes}
              // label="Drag and drop or browse dataset file."
              onTypeError={fileTypeHandler}
              children={<FileDropLayot fileName= {fileName} handleChange = {handleChange} />}
              onDrop={fileDropHandler}
              fileOrFiles={null}
            />
             
             <p className="primary-color fs-14 mb-3 mt-2 pb-2 ">
              {fileName &&  <img className='curpoint' src={CloseIcon} width={12} height={12} onClick={removeFileHandler} />} {fileName}  </p> 
          
            <p className="mb-3 mt-2 pb-2 primary-color fs-12">
              {INFO_SVG_ICON} Accepted dataset format: .xlsx only</p>
            <div>
              <Button className="primary-btn text-white fs-14"
                type="submit"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={importDatasets.bind(this)}>
                {IMPORT_SVG_ICON} Import
              </Button>
            </div>

          </div>
          {show && (
            <ImportFailAlert
              isShow={show}
              close={closeAlert}
              message={message}
            />)}

          {
            show && (message === FILE_TYPE_ERROR || message === SELECT_DATASET) ?
              <FailAlert
                isShow={show}
                close={closeAlert}
                message={message}
              /> : ""
          }

          {setSuccess && (
            <SuccessAlert
              isShow={showSuccess}
              close={closeAlert}
              message={message}
            />)}
        </Card.Body>
      </Card>
      }
     
 
    </div>
  )
}
