import React from 'react'
import { ALL_DB_SVG_ICON, IMPORT_SVG_ICON } from '../assets/svgFiles/svgIcons';

export default function SidebarMenu(props) {

  const { menuSelector, screen } = props;

  return (
    <div className="sidebar">
      <ul className="ps-0">
        <li className="list-unstyled border-bottom">
          <a className={`d-block text-decoration-none fs-14 ${screen === "ImportDatasets" ? "active" : ""}`} href="#" onClick={menuSelector.bind(this, "ImportDatasets")}>
            {IMPORT_SVG_ICON} Import Dataset
          </a>
        </li>
        <li className="list-unstyled border-bottom">
          <a className={`d-block text-decoration-none fs-14 ${screen === "AllDatasets" ? "active" : ""}`} href="#" onClick={menuSelector.bind(this, "AllDatasets")}>
            {ALL_DB_SVG_ICON} All Datasets
          </a>
        </li>
      </ul>
    </div>
  )
}