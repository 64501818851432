import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { ERROR_SVG } from '../../assets/svgFiles/svgIcons';
import { IMPORT_DB_MSG } from '../../helpers/alertMessages';
import { MsgLayout } from '../../layouts/MsgLayout';


export const ImportFailAlert = (props) => {
  const { isShow, close, message } = props;

  const [failMsg, setFailMsg] = useState([]);

  useEffect(() => {
    if (message !== undefined) {
      let allMsg = [];
      let msg = message[0];
      if (failMsg !== msg) {
        for (const data in msg) {
          allMsg.push(msg[data])
        }
        setFailMsg(allMsg)
      }
    }
  }, [])


  return (
    <Modal className="alert-message-modal" show={isShow} onHide={close.bind(this, false)} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header className="border-0 p-3" closeButton>
      </Modal.Header>

      <Modal.Body className="text-center">
        <div className="statuscircle danger">
          {ERROR_SVG}
        </div>
        <h4 className="text-black fs-14 fw-bold">Error Detected</h4>
        {message !== undefined && <MsgLayout failMsg={failMsg} />}
      </Modal.Body>

    </Modal>
  );
}