import moment from 'moment';


//This file is used for ag-grid columns and data represent related changes for Viewdatset component.

export const viewColDefs = [

    {
      headerName: 'Metadata',
      field: 'metadata',
      editable: false,
      //  cellRendererFramework: AgGridCheckbox,
      checkboxSelection: false,
      headerCheckboxSelection: false,
      suppressMovable: true,
      width: 120,
      headerClass: function (params) {
        return 'header-one';
      }
    },

    {
      headerName: 'Dataset Name',
      field: 'dataset_name',
      editable: false,
      suppressMovable: true,
      width: 350,
  
    },
  
    {
      headerName: 'Entities',
      field: 'number of entities',
      editable: false,
      suppressMovable: true,
      width: 120,
    },
  
    {
      headerName: 'User-Entity Relationship',
      field: 'number of user-entity_relationships',
      editable: false,
      suppressMovable: true,
      
    },
  
    {
      headerName: 'Users',
      field: 'number of users',
      editable: false,
      width: 120,
      suppressMovable: true,
  
    },
    {
      headerName: 'Upload By',
      field: 'who_uploaded',
      editable: false,
      width: 120,
      suppressMovable: true,
  
    },
     
  ];