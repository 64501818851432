export const EMAIL_REGEX= /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const FILE_TYPE = ["XLSX"];
export const TIME_OUT = 900000;

export const LOG_IN = "LOG_IN";
export const LOG_IN_LOADING = "LOG_IN_LOADING";
export const LOG_IN_SUCCESS = "LOG_IN_SUCCESS";
export const LOG_IN_FAIL = "LOG_IN_FAIL";

export const LOG_OUT = "LOG_OUT";
export const LOG_OUT_LOADING = "LOG_OUT_LOADING";
export const LOG_OUT_SUCCESS = "LOG_OUT_SUCCESS";
export const LOG_OUT_FAIL = "LOG_OUT_FAIL";

export const IMPORT_NEW_DATASET = "IMPORT_NEW_DATASET";
export const IMPORT_DATASET_LOADING = "IMPORT_DATASET_LOADING";
export const IMPORT_DATASET_SUCCESS = "IMPORT_DATASET_SUCCESS";
export const IMPORT_DATASET_FAIL = "IMPORT_DATASET_FAIL";

export const ALL_DATASET = "ALL_DATASET";
export const ALL_DATASET_LOADING = "ALL_DATASET_LOADING";
export const ALL_DATASET_SUCCESS = "ALL_DATASET_SUCCESS";
export const ALL_DATASET_FAIL = "ALL_DATASET_FAIL";

export const IMPORT_TO_DATABASE = "IMPORT_TO_DATABASE";
export const DATABASE_IMPORT_LOADING = "DATABASE_IMPORT_LOADING";
export const DATABASE_IMPORT_SUCCESS = "DATABASE_IMPORT_SUCCESS";
export const DATABASE_IMPORT_FAIL = "DATABASE_IMPORT_FAIL";

export const CLEAR_DATABASE = "CLEAR_DATABASE";
export const CLEAR_DATABASE_LOADING = "CLEAR_DATABASE_LOADING";
export const CLEAR_DATABASE_SUCCESS = "CLEAR_DATABASE_SUCCESS";
export const CLEAR_DATABASE_FAIL = "CLEAR_DATABASE_FAIL";

export const VIEW_DATASET = "VIEW_DATASET";
export const VIEW_DATASET_LOADING = "VIEW_DATASET_LOADING";
export const VIEW_DATASET_SUCCESS = "VIEW_DATASET_SUCCESS";
export const VIEW_DATASET_FAIL = "VIEW_DATASET_FAIL";

export const DELETE_DATASET = "DELETE_DATASET";
export const DELETE_DATASET_LOADING = "DELETE_DATASET_LOADING";
export const DELETE_DATASET_SUCCESS = "DELETE_DATASET_SUCCESS";
export const DELETE_DATASET_FAIL = "DELETE_DATASET_FAIL";

export const IMPORT_RESET = "IMPORT_RESET";
export const DB_RESP_RESET = "DB_RESP_RESET";
export const VIEW_RESP_RESET = "VIEW_RESP_RESET";
export const LOGIN_RESET = "LOGIN_RESET";





