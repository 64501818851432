import { call, put, takeLatest } from 'redux-saga/effects';
import { LOG_IN, LOG_IN_LOADING, LOG_IN_SUCCESS, LOG_IN_FAIL, } from '../helpers/constants';
import { loginUser } from '../services/loginServices/index';

function* fetchUser(action) {
  const payload = action.payload
  let user = payload.user;
  let password = payload.password;
  try {
    yield put({ type: LOG_IN_LOADING, loading: true });
    const loginData = yield call(loginUser, user, password);
    yield put({ type: LOG_IN_SUCCESS, dataLogin: loginData.data });
  } catch (e) {
    yield put({ type: LOG_IN_FAIL, message: e.message });
  }
}


function* loginSaga() {
  yield takeLatest(LOG_IN, fetchUser);

}

export default loginSaga;