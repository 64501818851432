import { LOG_OUT, LOG_IN_LOADING, LOG_IN_SUCCESS,
   LOG_IN_FAIL,LOGIN_RESET } from "../../helpers/constants";

const initialState = {
  user: {},
  isLoggedIn: false,
  loading: false,
  message: ""
}


export default function loginReducer(state = initialState, action) {

  switch (action.type) {
    case LOG_IN_LOADING:
      return {
        ...state,
        loading: true,
        user: {},
        isLoggedIn: false,
        message: ""

      }

    case LOG_IN_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.dataLogin.data,
        isLoggedIn: action.dataLogin.isSuccess,
        message: action.dataLogin.data.message

      }

    case LOG_IN_FAIL:
      return {
        ...state,
        loading: false,
        user: {},
        isLoggedIn: false,
        message: ""

      }

    case LOG_OUT:
      return {
        ...state,
        user: {},
        isLoggedIn: false
      }

      case LOGIN_RESET:
        return {
          ...state,
          loading: false,
          user: {},
          isLoggedIn: false,
          message: ""
  
        }
    default:
      return state
  }
}
