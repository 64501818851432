import axios from "axios";
import {
  IMPORT_DATASET_API, ALL_DATASET_API,
  IMPORT_TODB_API, CLEAR_DB_API,
  VIEW_DATASET_API, DELETE_DATASET_API
} from "../../apis";
import { GENRAL_HEADER, UPLOAD_API_HEADER } from "../../helpers/apiHeaders";


export const importNewDataset_service = async (dataset) => {

  return await axios({
    method: 'post',
    url: `${IMPORT_DATASET_API}`,
    headers: UPLOAD_API_HEADER,
    data: dataset

  })

};

export const showAllDataset_service = async () => {
  return await axios({
    method: 'get',
    url: `${ALL_DATASET_API}`,
    headers: GENRAL_HEADER,
  })

};


export const importDatasetDb_service = async (data) => {
  return await axios({
    method: 'post',
    url: `${IMPORT_TODB_API}`,
    headers: GENRAL_HEADER,
    data: {
      dataset_pk: data
    }
  })

};

export const clearDatabase_service = async () => {
  return await axios({
    method: 'delete',
    url: `${CLEAR_DB_API}`,
    headers: GENRAL_HEADER,

  })

};



export const viewDataset_service = async (data) => {
  return await axios({
    method: 'get',
    url: `${VIEW_DATASET_API}/${data}`,
    headers: GENRAL_HEADER,
  })

};



export const deleteDataset_service = async (data) => {
  return await axios({
    method: 'delete',
    url: `${DELETE_DATASET_API}/${data}`,
    headers: GENRAL_HEADER,

  })

};