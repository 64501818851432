import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { baseRoutes } from '../../routes/base_routes';
import { useNavigate } from 'react-router-dom';
import LogoImg from '../../assets/images/wcilogo.png';
import { useSelector, useDispatch } from 'react-redux';
import allActions from "../../redux/actions/allActions";
import { Users } from '../../helpers/config';
import { Row, Col, Form, Card, InputGroup, Button } from 'react-bootstrap';
import { FailAlert } from '../alertModel/FailAlert';
import { MANDATORY, INVALID_EMAIL } from '../../helpers/alertMessages';
import Footer from '../../common/Footer';
import { EMAIL_REGEX } from '../../helpers/constants';
import { PASSWORD_SVG_ICON, USERNAME_SVG_ICON } from '../../assets/svgFiles/svgIcons';





const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  localStorage.setItem("isLoggin", false)
  const loggedIn = useSelector(state => state.reducerLogin.isLoggedIn);
  const user = useSelector(state => state.reducerLogin);
  const errMsg = useSelector(state => state.reducerLogin.message);


  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(()=>{
    dispatch(allActions.loginActions.loginResetAction())
    setShow(false)
    setMessage("")
  },[])

  useEffect(() => {
    
    if (errMsg) {
      setShow(true)
      setMessage(errMsg)
    }

    else if (loggedIn === true) {
      setShow(false)
      setMessage("")
      navigate(`${baseRoutes.dashboard.path}`);
      localStorage.setItem("isLoggin", true);
    }

  }, [loggedIn, user, errMsg])


  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(EMAIL_REGEX);
  };



  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(allActions.loginActions.loginResetAction())
    const data = new FormData(event.currentTarget);
    let userName = data.get('username').trim();
    let password = data.get('password').trim();
    let EmailValid = validateEmail(userName);

    let arrFound = Users.some(function (ele) { return ele.username === userName && ele.password === password; });

    if (userName === "" && (password === "" || password === 0)) {
      setShow(true)
      setMessage(MANDATORY)
    }

    else if (EmailValid === null) {
      setShow(true)
      setMessage(INVALID_EMAIL)
    }

    else {
      dispatch(allActions.loginActions.loginUser(userName, password))
      if (loggedIn) {
        setShow(false)
        setMessage("")
      }

    }


  };

  const forgot = () => {
    navigate(`${baseRoutes.forgotPassword.path}`);
  }

  const closeAlert = (status) => {
    dispatch(allActions.loginActions.loginResetAction())
    setShow(status)
    setMessage("")
  }

  return (
    <div className="main-wrapper">
      <Container>
        <Row>
          <Col md={12}>
            <div className="account-page mt-5">
              <div className="login-logo mb-4">
                <img className="img-fluid" src={LogoImg} alt="wcimg" />
              </div>
              <Card className="border-0">
                <Typography className="mb-4" component="h1" variant="h5">
                  Log In
                </Typography>
                <Form onSubmit={handleSubmit}>
                  <Form.Label>Username</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Username"
                      aria-label="Username"
                      aria-describedby="username"
                      className="border-end-0"
                      name="username"
                      type='email'
                    />
                    <InputGroup.Text id="username" className="bg-transparent border-start-0">
                      {USERNAME_SVG_ICON}
                    </InputGroup.Text>
                  </InputGroup>
                  <Form.Label>Password</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Password"
                      aria-label="Password"
                      aria-describedby="password"
                      className="border-end-0"
                      name="password"
                      type='password'

                    />
                    <InputGroup.Text id="password" className="bg-transparent border-start-0">
                      {PASSWORD_SVG_ICON}
                    </InputGroup.Text>
                  </InputGroup>
                  <div className="d-grid gap-1">
                    <Button variant="success" size="lg" type="submit">
                      LOG IN
                    </Button>
                  </div>
                </Form>
              </Card>
            </div>
          </Col>
        </Row>
        {show && (
          <FailAlert
            isShow={show}
            close={closeAlert}
            message={message}
          />)}
        <Footer />
      </Container>
    </div>
  )
}

export default Login;


