import React, { useEffect, useState } from 'react';
import { Card, Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Header from '../../common/Header';
import SidebarMenu from '../../common/SidebarMenu';
import DatasetInitial from './DatasetInitial';
import Footer from '../../common/Footer';
import ImportDataSet from './ImportDataSet';


export default function Dashboard() {


  const user = useSelector(state => state.reducerLogin.user);
  const allData = useSelector(state => state.reducerDashboard.allDatasets);



  const isData = user.isDataset;
  const [screen, setScreen] = useState("AllDatasets");
  const [datasets, setDataSets] = useState(isData);
  const [menuIcon, setMenuIcon] = useState(false);
  const [allDataset, setAllData] = useState(allData);



  useEffect(() => {
    if (allDataset !== allData) {
      setAllData(allData)

    }
  }, [allDataset, allData])


  const sideMenuSelector = (data) => {
    setScreen(data)
  }

  const menuIconSelector = () => {
    setMenuIcon(!menuIcon)
  }



  return (
    <div className={menuIcon ? "main-wrapper mini-sidebar" : "main-wrapper"}>
      <Header iconClick={menuIconSelector} />
      <Container>

        <Card className="sidebar-menu border-0 p-0">
          <SidebarMenu menuSelector={sideMenuSelector} screen={screen} />
        </Card>

        {screen === "ImportDatasets" && <ImportDataSet />}
        {screen === "AllDatasets" && <DatasetInitial isDataSet={datasets} sideMenuSelector={sideMenuSelector} data={allDataset} />}
        <Footer />
      </Container>
    </div>
  )
}