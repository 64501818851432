import React, { useState } from 'react';
import DeleteIcon from '../assets/svgFiles/trash.svg';
import { useDispatch } from 'react-redux';
import allActions from '../redux/actions/allActions';
import { DELETE_DATA_MSG } from '../helpers/alertMessages';
import { ConfirmModel } from '../components/alertModel/ConfirmModel';

const AgGridDelete = (props) => {

  const [showConfirm, setShow] = useState(false)
  const [confirmFrom, setFrom] = useState(null)

  const dispatch = useDispatch();

  const deleteIconHandler = () => {
    setShow(true);
    setFrom("Delete_Icon")

  }

  const closeAlert = () => {
    setShow(false);
    setFrom(null);
  }

  const confirmSave = () => {
    const data = props.data
    dispatch(allActions.dashboardActions.datasetDeleteAction(data.metadata))
    setShow(false)
  }

  return (
    <div className='delete'>
      <img className="curpoint" src={DeleteIcon} onClick={deleteIconHandler} alt="deleteicon" />
      {
        showConfirm &&
        <ConfirmModel
          isShow={showConfirm}
          closeAlert={closeAlert}
          confirmSave={confirmSave}
          confirmFrom={confirmFrom}
        />
      }
    </div>
  );
}

export default AgGridDelete


