import React from 'react';
import logoImg from '../assets/images/wcilogo.png';
import allActions from '../redux/actions/allActions';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import baseRoutes from '../routes/base_routes';
import { Container, Button } from 'react-bootstrap';
import { MENU_SVG_ICON } from '../assets/svgFiles/svgIcons';



export default function Header(props) {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { iconClick } = props;
  const logout = () => {
    dispatch(allActions.loginActions.logOutUser())
    navigate(`${baseRoutes.login.path}`);

  }
  return (
    <div className="header-part bg-white">
      <Container>
        <div className="header d-flex align-items-center justify-content-between">
          <img src={logoImg} height={70} alt="wcilogo" />
          <div className="header-right">
            <div className="navbar-right">
              <ul className="ps-0 mb-0">
                <li className="d-inline-block list-unstyled"><a href="#" className="" onClick={logout}>Logout</a></li>
                <li className="d-inline-block list-unstyled ms-3 d-md-none">
                  <Button className="hemburger" onClick={iconClick.bind(this)}>
                    {MENU_SVG_ICON}
                  </Button>
                </li>
              </ul>
            </div>

          </div>
        </div>
      </Container>
    </div>
  )
}
