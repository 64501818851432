import React, { useEffect } from 'react';


export default function ForgotPassword() {

  return (
    <div>
      <h3>
        ForgotPassword
      </h3>
    </div>
  )
}
