import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Button } from 'react-bootstrap';
import AllDataSets from './AllDataSets';
import { DB_SVG_ICON, IMPORT_SVG_ICON } from '../../assets/svgFiles/svgIcons';
import allActions from '../../redux/actions/allActions';




export default function DatasetInitial(props) {

  const { isDataSet, sideMenuSelector, data } = props;
  let dataLength = data.length

  const [isData, setIsdata] = useState(isDataSet);
  const [dataLen, setDataLen] = useState(dataLength);


  const dispatch = useDispatch();

  useEffect(() => {
    if (isData) {
      dispatch(allActions.dashboardActions.getAllDataSets())
    }

  }, [])


  const importDatasets = () => {
    sideMenuSelector("ImportDatasets")
  }

  return (
    <div className='dash-content'>
      {
        isData ? <AllDataSets dataLen={dataLen} /> : <Card className="content-wrapper d-flex align-items-center justify-content-center border-0">
          <div className="nodata-found d-flex align-items-center h-100 justify-content-center flex-column text-center">
            <div className="nodata-img mb-3">
              {DB_SVG_ICON}
            </div>
            <div className="nodata-content">
              <h3 className="mb-2 pb-1 primary-color">There are no Datasets</h3>
              <p className="mb-3 pb-2 primary-color fs-14">Click on the import button to import, view and upload dataset</p>
              <Button className="primary-btn text-white fs-14" type="button" variant="contained" sx={{ mt: 3, mb: 2 }} onClick={importDatasets} >
                {IMPORT_SVG_ICON}  Import
              </Button>

            </div>
          </div>
        </Card>

      }

    </div>
  )
}
