export const Users= [{
    username:'wci1@yopmail.com',
    password:'1234'
},
{
    username:'wci2@yopmail.com',
    password:'1234'
},
{
    username:'wci3@yopmail.com',
    password:'1234'
},
{
    username:'johndoe2001@yopmail.com',
    password:"WCIpass1"
}
]