import './App.css';
import { createBrowserHistory } from "history";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import React,{Suspense} from 'react';
import { baseRoutes } from './routes/base_routes';
import Login from './components/loginModule/Login';
import ForgotPassword from './components/loginModule/ForgotPassword';
import { useSelector } from 'react-redux';
import Dashboard from './components/dashboardModule/Dashboard';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch } from 'react-redux';
import allActions from './redux/actions/allActions';
import { TIME_OUT } from './helpers/constants';



const App = (props) => {
  const dispatch = useDispatch();
  const hist = createBrowserHistory();
  const loggedIn = useSelector(state => state.reducerLogin.isLoggedIn);
  // const user = useSelector(state => state.reducerLogin);


  const handleOnIdle = event => {
    dispatch(allActions.loginActions.logOutUser())

  }

  const handleOnActive = event => {

  }

  const handleOnAction = (e) => {

  }

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: TIME_OUT,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500
  })


  return (
    <div>
      <Suspense fallback={
             <div className="loader"></div>
          }>
      <BrowserRouter>

        <Routes>
          {/* <PrivateRoute path={baseRoutes.dashboard.path} component={Admin} /> */}
          <Route exact  path={baseRoutes.login.path} element={<Login />} />
          <Route exact path={baseRoutes.forgotPassword.path} element={<ForgotPassword />} />
          <Route exact path={baseRoutes.dashboard.path} element={loggedIn ? <Dashboard /> : <Navigate replace to={baseRoutes.login.path} />} />
          {/* <Route path='*' element={<PageNotFound />} /> */}
        </Routes>
      </BrowserRouter>
      </Suspense>
    </div>
  );
}

export default App;
