export const USERNAME_SVG_ICON = <svg xmlns="http://www.w3.org/2000/svg" width="10.778" height="12" viewBox="0 0 10.778 12">
    <g id="Icon_feather-user" data-name="Icon feather-user" transform="translate(-1.5 -1)">
        <path data-name="Path 12077" d="M11.778,11.167V9.944A2.444,2.444,0,0,0,9.333,7.5H4.444A2.444,2.444,0,0,0,2,9.944v1.222" transform="translate(0 1.333)" fill="none" stroke="#4d8da4" />
        <path data-name="Path 12078" d="M8.889,3.944A2.444,2.444,0,1,1,6.444,1.5,2.444,2.444,0,0,1,8.889,3.944Z" transform="translate(0.444 0)" fill="none" stroke="#4d8da4" />
    </g>
</svg>

export const PASSWORD_SVG_ICON = <svg xmlns="http://www.w3.org/2000/svg" width="14.414" height="14.414" viewBox="0 0 14.414 14.414">
    <g id="Icon_feather-eye-off" data-name="Icon feather-eye-off" transform="translate(0.707 0.707)">
        <path id="Path_12073" data-name="Path 12073" d="M10.843,11.571a5.95,5.95,0,0,1-3.51,1.217c-4.136,0-6.5-4.727-6.5-4.727a10.9,10.9,0,0,1,2.99-3.51M6.092,3.475a5.389,5.389,0,0,1,1.241-.142c4.136,0,6.5,4.727,6.5,4.727a10.932,10.932,0,0,1-1.276,1.885M8.586,9.313A1.773,1.773,0,1,1,6.081,6.808" transform="translate(-0.833 -1.561)" fill="none" stroke="#4d8da4" />
        <path id="Path_12074" data-name="Path 12074" d="M.833.833l13,13" transform="translate(-0.833 -0.833)" fill="none" stroke="#4d8da4" />
    </g>
</svg>

export const INFO_SVG_ICON = <svg className="me-1" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
    <path id="Icon_awesome-info-circle" data-name="Icon awesome-info-circle" d="M6.563.563a6,6,0,1,0,6,6A6,6,0,0,0,6.563.563Zm0,2.661A1.016,1.016,0,1,1,5.546,4.24,1.016,1.016,0,0,1,6.563,3.224ZM7.917,9.369a.29.29,0,0,1-.29.29H5.5a.29.29,0,0,1-.29-.29V8.788A.29.29,0,0,1,5.5,8.5h.29V6.95H5.5a.29.29,0,0,1-.29-.29V6.079a.29.29,0,0,1,.29-.29H7.046a.29.29,0,0,1,.29.29V8.5h.29a.29.29,0,0,1,.29.29Z" transform="translate(-0.563 -0.563)" fill="#4d8da4" />
</svg>

export const IMPORT_SVG_ICON = <svg className="me-1" xmlns="http://www.w3.org/2000/svg" width="15.812" height="13.012" viewBox="0 0 15.812 13.012">
    <g id="Icon_feather-upload-cloud" data-name="Icon feather-upload-cloud" transform="translate(-0.062 -1.488)">
        <path data-name="Path 12091" d="M10.667,10.667,8,8,5.333,10.667" fill="none" stroke="currentColor" />
        <path data-name="Path 12092" d="M8,8v6" fill="none" stroke="currentColor" />
        <path data-name="Path 12093" d="M13.593,12.26A3.333,3.333,0,0,0,12,6h-.84A5.333,5.333,0,1,0,2,10.867" fill="none" stroke="currentColor" />
        <path data-name="Path 12094" d="M10.667,10.667,8,8,5.333,10.667" fill="none" stroke="currentColor" />
    </g>
</svg>

export const DB_SVG_ICON = <svg xmlns="http://www.w3.org/2000/svg" width="101" height="97" viewBox="0 0 101 97">
    <g id="Group_20052" data-name="Group 20052" transform="translate(-752 -256)">
        <g id="Icon_feather-database" data-name="Icon feather-database" transform="translate(750.75 255.5)">
            <path id="Path_12088" data-name="Path 12088" d="M83.25,17.077c0,8.6-18.132,15.577-40.5,15.577S2.25,25.68,2.25,17.077,20.382,1.5,42.75,1.5s40.5,6.974,40.5,15.577Z" transform="translate(0 0)" fill="none" stroke="#4d8da4" />
            <path id="Path_12089" data-name="Path 12089" d="M83.25,9c0,8.619-18,15.577-40.5,15.577S2.25,17.619,2.25,9" transform="translate(0 36.278)" fill="none" stroke="#4d8da4" />
            <path id="Path_12090" data-name="Path 12090" d="M2.25,3.75V65.04c0,7.267,18,13.133,40.5,13.133s40.5-5.866,40.5-13.133V3.75" transform="translate(0 13.327)" fill="none" stroke="#4d8da4" />
        </g>
        <g id="Ellipse_1" data-name="Ellipse 1" transform="translate(816 316)" fill="#fff" stroke="#4d8da4">
            <circle cx="18.5" cy="18.5" r="18.5" stroke="none" />
            <circle cx="18.5" cy="18.5" r="17.5" fill="none" />
        </g>
        <path id="Path_12117" data-name="Path 12117" d="M.667-5.116H-.667l-.647-13.444H1.314ZM-1.542-1.346q0-1.727,1.523-1.727a1.45,1.45,0,0,1,1.136.444,1.855,1.855,0,0,1,.4,1.282A1.82,1.82,0,0,1,1.111-.083,1.443,1.443,0,0,1-.019.368a1.544,1.544,0,0,1-1.092-.4A1.722,1.722,0,0,1-1.542-1.346Z" transform="translate(834.542 343.561)" fill="#4d8da4" />
    </g>
</svg>

export const MENU_SVG_ICON = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M0 96C0 78.33 14.33 64 32 64H416C433.7 64 448 78.33 448 96C448 113.7 433.7 128 416 128H32C14.33 128 0 113.7 0 96zM0 256C0 238.3 14.33 224 32 224H416C433.7 224 448 238.3 448 256C448 273.7 433.7 288 416 288H32C14.33 288 0 273.7 0 256zM416 448H32C14.33 448 0 433.7 0 416C0 398.3 14.33 384 32 384H416C433.7 384 448 398.3 448 416C448 433.7 433.7 448 416 448z" fill="currentColor" /></svg>

export const ALL_DB_SVG_ICON = <svg xmlns="http://www.w3.org/2000/svg" width="12.7" height="14" viewBox="0 0 12.7 14">
    <g id="Icon_feather-database" data-name="Icon feather-database" transform="translate(-1.75 -1)">
        <path data-name="Path 12088" d="M13.95,3.75C13.95,4.993,11.331,6,8.1,6S2.25,4.993,2.25,3.75,4.869,1.5,8.1,1.5s5.85,1.007,5.85,2.25Z" transform="translate(0)" fill="none" stroke="currentColor" />
        <path data-name="Path 12089" d="M13.95,9c0,1.245-2.6,2.25-5.85,2.25S2.25,10.245,2.25,9" transform="translate(0 -1.176)" fill="none" stroke="currentColor" />
        <path data-name="Path 12090" d="M2.25,3.75V12.6c0,1.05,2.6,1.9,5.85,1.9s5.85-.847,5.85-1.9V3.75" fill="none" stroke="currentColor" />
    </g>
</svg>

export const IMPORT_DB_SVG = <svg xmlns="http://www.w3.org/2000/svg" width="12.7" height="14" viewBox="0 0 12.7 14"><g id="Icon_feather-database" data-name="Icon feather-database" transform="translate(-1.75 -1)"><path data-name="Path 12088" d="M13.95,3.75C13.95,4.993,11.331,6,8.1,6S2.25,4.993,2.25,3.75,4.869,1.5,8.1,1.5s5.85,1.007,5.85,2.25Z" transform="translate(0)" fill="none" stroke="currentColor"></path><path data-name="Path 12089" d="M13.95,9c0,1.245-2.6,2.25-5.85,2.25S2.25,10.245,2.25,9" transform="translate(0 -1.176)" fill="none" stroke="currentColor"></path><path data-name="Path 12090" d="M2.25,3.75V12.6c0,1.05,2.6,1.9,5.85,1.9s5.85-.847,5.85-1.9V3.75" fill="none" stroke="currentColor"></path></g></svg>

export const CLEAR_DB_SVG = <svg xmlns="http://www.w3.org/2000/svg" width="15.812" height="13.012" viewBox="0 0 352 512"><path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z" fill="currentColor" /></svg>

export const ERROR_SVG =  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" fill="currentColor" /></svg>

export const SUCCESS_SVG =   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z" fill="currentColor" /></svg>