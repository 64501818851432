import {
  IMPORT_DATASET_LOADING,
  IMPORT_DATASET_SUCCESS,
  IMPORT_DATASET_FAIL,
  ALL_DATASET_LOADING,
  ALL_DATASET_SUCCESS,
  ALL_DATASET_FAIL,
  DATABASE_IMPORT_LOADING,
  DATABASE_IMPORT_SUCCESS,
  DATABASE_IMPORT_FAIL,
  CLEAR_DATABASE_LOADING,
  CLEAR_DATABASE_SUCCESS,
  CLEAR_DATABASE_FAIL,
  VIEW_DATASET_LOADING,
  VIEW_DATASET_SUCCESS,
  VIEW_DATASET_FAIL,
  DELETE_DATASET_LOADING,
  DELETE_DATASET_SUCCESS,
  DELETE_DATASET_FAIL,
  IMPORT_RESET,
  DB_RESP_RESET,
  VIEW_RESP_RESET,
} from "../../helpers/constants";

const initialState = {

  loading: false,
  dataset: [],
  message: "",
  allDatasets: [],
  status:null,
  dbImportData:[],
  viewData:[]
}


let dashboardReducer = (state = initialState, action) => {

  switch (action.type) {
    case IMPORT_DATASET_LOADING:
      return {
        ...state,
        loading: true,
        status:null

      }

    case IMPORT_DATASET_SUCCESS:
      let dataresp = action.payload.importData.data
      return {
        ...state,
        
        loading: false,
        status:dataresp.status,
        message:dataresp.data

      }

    case IMPORT_DATASET_FAIL:
      let datafail = action.payload.importData.data;
      return {
        ...state,
        loading: false,
        status:datafail.status,
        message: datafail.data
      }


      

    case ALL_DATASET_LOADING:
      return {
        ...state,
        loading: true,
        allDatasets: []

      }

    case ALL_DATASET_SUCCESS:
      let data = action.payload.result.data.results

      return {
        ...state,
        loading: false,
        allDatasets: data
      }

    case ALL_DATASET_FAIL:
      return {
        ...state,
        loading: false,
        allDatasets: []

      }

    case DATABASE_IMPORT_LOADING:
      return {
        ...state,
        loading: true,

      }

    case DATABASE_IMPORT_SUCCESS:
      let dbSuccess = action.payload.newdata

      return {
        ...state,
        loading: false,
        dbImportData:dbSuccess,
  
      }


    case DATABASE_IMPORT_FAIL:
      let failData = action.payload.newdata
      return {
        ...state,
        loading: false,
        dbImportData:failData,
        message:failData.data


      }

    case CLEAR_DATABASE_LOADING:
      return {
        ...state,
        loading: true,

      }

    case CLEAR_DATABASE_SUCCESS:

      return {
        ...state,
        loading: false,
      }


    case CLEAR_DATABASE_FAIL:
      return {
        ...state,
        loading: false,

      }

    case VIEW_DATASET_LOADING:
      return {
        ...state,
        loading: true,
        viewData:[]

      }

    case VIEW_DATASET_SUCCESS:
let dataView = action.payload.viewData.data
      return {
        ...state,
        loading: false,
        viewData:dataView
      }


    case VIEW_DATASET_FAIL:
      return {
        ...state,
        loading: false,
        viewData:[]

      }

    case DELETE_DATASET_LOADING:
      return {
        ...state,
        loading: true,

      }

    case DELETE_DATASET_SUCCESS:

      return {
        ...state,
        loading: false,
      }


    case DELETE_DATASET_FAIL:
      return {
        ...state,
        loading: false,

      }


    case IMPORT_RESET:
      return {
        ...state,
        loading: false,
        status: null,
        message:""

      }


    case DB_RESP_RESET:
      return {
        ...state,
        loading: false,
        status: null,
        dbImportData:[],
        message:""

      }

      case VIEW_RESP_RESET:
        return {
          ...state,
          loading: false,
          status: null,
          viewData:[]
  
        }
        
       

    default:
      return state
  }
}

export default dashboardReducer;