/*!
This File contain all route that will use in ./src/index.js & in navigations
*/

export const basePath = "";

export const baseRoutes = {
  login: {
    path: `/`,
    pathName: "Login"
  },
  forgotPassword: {
    path: `/forgotpassword`,
    pathName: "Forgot Password"
  },
  resetPassword: {
    path: `/reset-password`,
    pathName: "Reset Password"
  },
  dashboard: {
    path: `/dashboard`,
    pathName: "Dashboard",
    // useLink: `/facility`
  },
};

export default baseRoutes;
