import React from 'react'
import FileIcon from './../assets/images/file-icon.svg';

export default function FileDropLayot() {
  return (
    <div className="drag-and-drop">
      <div className="file-icon mb-3">
        <img className="img-fluid" src={FileIcon} alt="fileicon"/>
      </div>
      <p className="m-0 fs-14 primary-color">Drag and drop or browse your dataset file.</p>
    </div>
  )
}