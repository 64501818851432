import { LOG_IN, LOG_OUT,LOGIN_RESET } from "../../helpers/constants";

const loginUser = (user, password) => {
    return {
        type: LOG_IN,
        payload: {
            user: user,
            password: password
        }
    }
}

const logOutUser = () => {
    return {
        type: LOG_OUT
    }
}

const loginResetAction = () => {
    return {
        type: LOGIN_RESET,
    }
}


export default {
    loginUser,
    logOutUser,
    loginResetAction
}
